<template>
    <b-overlay :show="loading">
        <pusat-modal v-if="hasPermissionApprove && (isOwner || isGM)" @submit="approve"></pusat-modal>
        <b-card class="mb-2">
            <header class="d-flex justify-content-between align-items-center mb-1">
                <div class="d-flex align-item-center">
                    <p>Tanggal Retur: {{ retur ? humanDate(retur.tanggal) : '' }}</p>
                </div>
                <div>
                    <b-button size="sm" v-if="!hasPermissionApprove && isAdminGudang && retur.lapor == 0" variant="success" @click.prevent="laporkan">
                        Laporkan
                    </b-button>
                    <b-button size="sm" v-if="hasPermissionApprove && (isOwner || isGM) && retur.approve == 0" variant="success" @click.prevent="openpusatModal">Setujui Retur</b-button>
                    <b-button size="sm" v-else-if="hasPermissionApprove && (isOwner || isGM) && retur.approve > 0" variant="outline-danger" @click.prevent="cancel">Batalkan</b-button>
                </div>
            </header>
            <b-row>
                <b-col sm="12" md="6" lg="4">
                    <span class="d-block">Pembuat</span>
                    <strong class="d-block mb-1">{{retur.pembuat ? retur.pembuat.nama_lengkap : '-'}}</strong>
                    <span class="d-block">Keterangan</span>
                    <strong class="d-block mb-2">{{retur ? retur.keterangan : ''}}</strong>
                    
                </b-col>
                <b-col sm="12" md="6" lg="4" v-if="(isOwner || isGM) && hasPermissionApprove">
                    <span class="d-block">Info Gudang</span>
                    <strong class="d-block mb-1">{{retur.gudang ? retur.gudang.nama_gudang : '-'}}</strong>
                    <span class="d-block" >Informasi pusat</span>
                    <strong class="d-block mb-1">{{retur && retur.pusat ? retur.pusat.instansi : '-'}}</strong>
                </b-col>
                <b-col sm="12" md="6" lg="4">
                    <b-badge class="mb-1" variant="success" v-if="retur.lapor == 1">Sudah dilaporkan</b-badge>
                    <b-badge class="mb-1" variant="danger" v-else>Belum dilaporkan</b-badge>
                    <span class="d-block">Status</span>
                    <b-badge class="mb-1" variant="primary">{{ retur ? retur.status : '' }}</b-badge>
                </b-col>
            </b-row>
        </b-card>
        <h4 class="mb-1">Rincian Barang<span v-if="retur.rincian && retur.rincian.length > 0">({{ retur.rincian.length }})</span></h4>
        <b-card class="mb-2">
            <b-table responsive :fields="fields" :items="retur.rincian">
                <template #cell(barang)="{item}">
                    <span v-if="item.barang">
                        {{item.barang.nama}}
                    </span>
                    <i v-else class="text-danger">Barang tidak ada.</i>
                </template>
                <template #cell(satuan)="{item}">
                    {{ item.barang && item.barang.satuan ? item.barang.satuan.satuan : '' }}
                </template>
            </b-table>
        </b-card>
        <section class="d-flex align-items-center justify-content-end">
            <!-- <div v-if="hasPermissionApprove && retur">
                <b-button variant="success" v-if="pembelian.izin == 0" @click.prevent="approve">Approve Pembelian</b-button>
            </div>
            <div v-if="hasPermissionCancel && pembelian" class="ml-1">
                <b-button variant="outline-danger" v-if="pembelian.izin == 0" @click.prevent="cancel">Batalkan Pembelian</b-button>
            </div> -->
        </section>

    </b-overlay>
</template>
<script>
import {BCard, BTable, BBadge, BRow, BCol, BButton, BOverlay} from 'bootstrap-vue'
import PusatModal from './components/PusatModal.vue'
export default {
    components: {
        BCard, BTable, BBadge, BRow, BCol, BButton, BOverlay, PusatModal
    },
    data: () => ({
        listStatus: [],
        loading: false,
        retur: {},
        fields: [
            {
                key: 'barang',
                label: 'Nama Barang'
            },
            {
                key: 'qty',
                label: 'Qty'
            },
            {
                key: 'satuan',
                label: 'Satuan'
            }
        ],
        hasPermissionApprove: false,
        confirmOption: {
            title: 'Setujui Pembelian ini?',
            text: `Dengan ini finance dapat meng-inputkan pembayaran pembelian`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            },
            buttonsStyling: false
        }
    }),
    methods: {
        async approve(payload) {
            try {
                this.loading = true
                this.$bvModal.hide('pusat-modal')
                await this.$store.dispatch('tokoreturpusat/save', payload)
                this.displaySuccess({
                    message: 'Retur berhasil disetujui!'
                })
                this.loading = false
                setTimeout(() => this.$router.push('/cabang/retur-pusat'), 1000)
            }
            catch(e) {
                this.$bvModal.show('pusat-modal')
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        openpusatModal() {
            this.$bvModal.show('pusat-modal')
        },
        laporkan() {
            this.$swal({
                title: 'Laporkan retur ini?',
                text: `Dengan ini owner dapat memproses retur anda`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger ml-1',
                },
                buttonsStyling: false
            })
            .then(async res => {
                if(res.value ) {
                    const payload = {
                        id: this.retur.id,
                        lapor: 1
                    }

                    try {
                        this.loading = true
                        await this.$store.dispatch('tokoreturpusat/save', [payload])
                        this.loading = false
                        this.displaySuccess({
                            message: 'Retur ini sudah dilaporkan ke Owner!'
                        })

                        setTimeout(() => this.$router.push('/cabang/retur-pusat'), 1000)
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }
                }
            })
        },
        async print() {
            try {
                this.loading = true
                const response = await this.$store.dispatch('pembelian/print', this.$route.params.id)
                this.loading = false
                window.open(URL.createObjectURL(response))
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async doUpdate(payload) {
            try {
                const actionName = payload.tolak == 1 ? 'pembelian/cancel' : 'pembelian/save'
                await this.$store.dispatch(actionName, [payload])
                const params = {
                    order: 'desc'
                }
                if(this.isGM || this.isOwner) params.jenis = 2
                await this.$store.dispatch('pembelian/getData', params)
                
                this.getPembelian()

                return Promise.resolve()
            }
            catch(e) {
                this.displayError(e)
                return Promise.reject(e)
            }
        },
        cancel() {
            this.confirmOption.title = 'Batalkan retur ini?'
            this.confirmOption.text = 'Anda akan membatalkan retur ini'
            this.$swal(this.confirmOption)
            .then(async res => {
                if(res.value) {
                    const payload = {
                        id: this.$route.params.id,
                        approve: 0,
                        pusat_id: this.retur.pusat_id
                    }
                    
                    try {
                        this.loading = true
                        await this.$store.dispatch('tokoreturpusat/save', [payload])
                        this.loading = false
                        this.displaySuccess({
                            message: 'Retur telah dibatalkan'
                        })

                        setTimeout(() => this.$router.push('/cabang/retur-pusat'), 1000)
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }

                }
            })
        },
        getRetur() {
            const returs = this.$store.state['tokoreturpusat'].datas
            if(!returs) {
                this.$router.push('/cabang/retur-pusat')
            }

            const retur = returs.find(item => item.id == this.$route.params.id)
            if(!retur) {
                this.$router.push('/cabang/retur-pusat')
            }

            if(this.isGM && retur.jenis == 1) {
                this.$router.push('/cabang/retur-pusat')
            }

            this.retur = retur
        },
        async getStatusApprove() {
            const menu = await this.currentMenu(this.$route.meta.parent)
            const params = {
                level_id: this.user.level.id
            }
            if(menu) {
                params.menu_id = menu.id
            }
            this.listStatus = await this.$store.dispatch('statusrole/getData', params)

            // data_status => 1 approve gm, data_status => 2 pay finance
            const hasPermissionApprove = this.listStatus.some(status => status.data_status == 1)
            
            this.hasPermissionApprove = hasPermissionApprove
        }
    },
    async created() {
        await this.getRetur()
        await this.getStatusApprove()
    }
}
</script>